.compact-dashboard {
  background: #f0f4f8;
  padding: 1.25rem;
  min-height: 100vh;
}

.top-bar {
  background: linear-gradient(to right, #ffffff, #f8fafc);
  padding: 0.875rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.03);
  margin-bottom: 1.25rem;
  border: 1px solid #e2e8f0;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  gap: 1rem;
}

.search-box {
  position: relative;
  max-width: 350px;
}

.search-box {
  position: relative;
  flex: 1;
  max-width: 400px;
}

.search-icon {
  position: absolute;
  left: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
  color: #64748b;
  font-size: 0.875rem;
  z-index: 1;
}

.compact-select {
  width: 100% !important;
}

.compact-select .p-multiselect {
  border-radius: 8px !important;
  font-size: 0.875rem !important;
  border: 1px solid #e2e8f0 !important;
  transition: all 0.2s !important;
  background: white !important;
}

.compact-select .p-multiselect:hover {
  border-color: #94a3b8 !important;
}

.compact-select .p-multiselect-label {
  padding: 0.5rem 0.75rem 0.5rem 2.25rem !important;
  color: #334155 !important;
}

.leave-compact-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.75rem;
  margin-bottom: 1.25rem;
}

.compact-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.03);
  transition: all 0.2s;
  border: 1px solid #e2e8f0;
  max-width: 250px;
  margin: 0 auto;
  width: 100%;
}

.compact-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.06);
  border-color: #cbd5e1;
}

.card-top {
  padding: 0.75rem;
  background: linear-gradient(to right, #f1f5f9, #f8fafc);
  border-bottom: 1px solid #e2e8f0;
}

.user-brief {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.user-avatar {
  width: 28px;
  height: 28px;
  background: linear-gradient(145deg, #e2e8f0, #f1f5f9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64748b;
  font-size: 0.813rem;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.04);
}

.user-name {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1e293b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leave-list {
  padding: 0.75rem;
}

.leave-item {
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s;
  margin-bottom: 0.375rem;
}

.leave-item:last-child {
  margin-bottom: 0;
}

.leave-info {
  display: grid;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.leave-label {
  font-size: 0.813rem;
  color: #475569;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.leave-values {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.375rem;
  margin-left: auto;
}

.value {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.813rem;
  padding: 0.25rem 0.375rem;
  border-radius: 6px;
  white-space: nowrap;
  font-weight: 500;
  width: 45px;
  justify-content: center;
}

.value.available {
  background: linear-gradient(145deg, #dcfce7, #f0fdf4);
  color: #15803d;
  border: 1px solid #86efac;
}

.value.used {
  background: linear-gradient(145deg, #fee2e2, #fef2f2);
  color: #b91c1c;
  border: 1px solid #fca5a5;
}

.edit-icon {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  color: #64748b;
  padding: 0.25rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.75rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.edit-icon:hover {
  background: #f1f5f9;
  color: #3b82f6;
  border-color: #93c5fd;
}

.compact-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background: linear-gradient(to right, #ffffff, #f8fafc);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.03);
  border: 1px solid #e2e8f0;
}

.nav-btn {
  background: white;
  border: 1px solid #e2e8f0;
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  color: #475569;
  font-size: 0.813rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.nav-btn:hover:not(:disabled) {
  background: #f1f5f9;
  border-color: #94a3b8;
  color: #1e293b;
}

.nav-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-indicator {
  font-size: 0.813rem;
  color: #475569;
  font-weight: 500;
}

.compact-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.spinner-ring {
  width: 32px;
  height: 32px;
  border: 2px solid #e2e8f0;
  border-top-color: #3b82f6;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.compact-modal {
  max-width: 320px;
  margin: 1.75rem auto;
}

.compact-modal .modal-content {
  border-radius: 10px;
  border: none;
  box-shadow: 0 4px 6px rgba(0,0,0,0.06);
}

.compact-modal .modal-header {
  padding: 0.875rem 1rem;
  background: linear-gradient(to right, #f1f5f9, #f8fafc);
  border-bottom: 1px solid #e2e8f0;
}

.compact-modal .modal-title {
  font-size: 0.938rem;
  font-weight: 600;
  color: #1e293b;
}

.compact-modal .modal-body {
  padding: 1rem;
}

.compact-form .form-group {
  margin-bottom: 1rem;
}

.compact-form label {
  font-size: 0.813rem;
  color: #475569;
  font-weight: 500;
  margin-bottom: 0.375rem;
}

.compact-input {
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  transition: all 0.2s;
  width: 100%;
  color: #1e293b;
  background: white;
}

.compact-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
}

.compact-input:read-only {
  background: #f8fafc;
  color: #64748b;
}

.compact-modal .modal-footer {
  padding: 0.875rem 1rem;
  border-top: 1px solid #e2e8f0;
  display: flex;
  gap: 0.625rem;
}

/* Responsive breakpoints */
@media (max-width: 1600px) {
  .leave-compact-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1300px) {
  .leave-compact-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1000px) {
  .leave-compact-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .compact-dashboard {
    padding: 0.875rem;
  }

  .search-box {
    max-width: 100%;
  }

  .leave-compact-grid {
    grid-template-columns: 1fr;
  }

  .compact-pagination {
    flex-wrap: wrap;
    gap: 0.75rem;
  }
}

.leave-container {
  padding: 1.25rem;
  background-color: #f8fafc;
  min-height: 100vh;
}

.filter-card {
  background: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;
}

.filter-card .card-body {
  padding: 1.25rem;
}

/* Search and Filter Styles */
.search-wrapper, .filter-wrapper {
  position: relative;
}

.search-icon, .filter-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #94a3b8;
  z-index: 1;
}

.search-input, .filter-select {
  height: 42px;
  padding-left: 2.75rem !important;
  border-radius: 8px !important;
  border: 1px solid #e2e8f0 !important;
  background-color: #f8fafc !important;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.search-input:focus, .filter-select:focus {
  border-color: #3b82f6 !important;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.15) !important;
  background-color: white !important;
}

.leave-status-indicators {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  font-size: 0.875rem;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #475569;
}

/* Leave Card Styles */
.leave-card {
  border: none !important;
  border-radius: 12px !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  height: 100%;
}

.leave-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.leave-card .card-header {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  border: none;
  padding: 1rem;
}

.leave-card .card-header h6 {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
  color: white;
}

.leave-card .card-body {
  padding: 1rem !important;
}

.leave-grid {
  display: grid;
  gap: 0.75rem;
}

.leave-item {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
}

.leave-item:hover {
  background: white;
  border-color: #3b82f6;
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
}

.leave-type {
  font-weight: 500;
  color: #1e293b;
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.leave-stats {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 0.875rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  flex: 1;
  transition: transform 0.15s ease;
}

.stat-item:hover {
  transform: translateY(-1px);
}

.stat-item.available {
  background: #dcfce7;
  color: #16a34a;
  border: 1px solid rgba(22, 163, 74, 0.1);
}

.stat-item.used {
  background: #fee2e2;
  color: #dc2626;
  border: 1px solid rgba(220, 38, 38, 0.1);
}

.edit-btn {
  color: #64748b;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  border-radius: 6px;
  transition: all 0.2s ease;
  padding: 0;
}

.edit-btn:hover {
  color: #3b82f6;
  background: rgba(59, 130, 246, 0.1);
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem 0;
}

.pagination-container .btn {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  border: none;
  padding: 0.625rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination-container .btn:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(59, 130, 246, 0.2);
}

.pagination-container .btn:disabled {
  background: #e2e8f0;
  cursor: not-allowed;
}

.page-info {
  color: #475569;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 120px;
  text-align: center;
}

/* Modal Styling */
.modal-content {
  border: none;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modal-header {
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  border-radius: 12px 12px 0 0;
  padding: 1rem 1.25rem;
  border: none;
}

.modal-header .modal-title {
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

.modal-header .btn-close {
  filter: brightness(0) invert(1);
  opacity: 0.8;
}

.modal-body {
  padding: 1.5rem;
}

.current-stats {
  background: #f8fafc;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  border: 1px solid #e2e8f0;
}

/* Form Controls */
.form-control {
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 0.625rem 0.875rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.form-control:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.15);
}

.form-label {
  font-weight: 500;
  color: #475569;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .leave-container {
    padding: 1rem;
  }

  .leave-status-indicators {
    justify-content: flex-start;
    margin-top: 1rem;
  }
  
  .search-wrapper, .filter-wrapper {
    margin-bottom: 1rem;
  }

  .pagination-container {
    flex-direction: column;
    gap: 0.75rem;
  }

  .page-info {
    order: -1;
  }
}

/* MultiSelect Custom Styling */
.p-multiselect {
  width: 100% !important;
  border-radius: 8px !important;
  border: 1px solid #e2e8f0 !important;
  font-size: 0.875rem !important;
  transition: all 0.2s ease !important;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #3b82f6 !important;
}

.p-multiselect.p-focus {
  border-color: #3b82f6 !important;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.15) !important;
}

.p-multiselect-panel {
  border-radius: 8px !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
              0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.p-multiselect-header {
  padding: 0.75rem !important;
  border-bottom: 1px solid #e2e8f0 !important;
}

.p-multiselect-items {
  padding: 0.5rem !important;
}

.p-multiselect-item {
  padding: 0.625rem 0.875rem !important;
  border-radius: 6px !important;
  margin-bottom: 0.25rem !important;
  transition: all 0.2s ease !important;
}

.p-multiselect-item:hover {
  background: #f8fafc !important;
}

.p-multiselect-item.p-highlight {
  background: #eff6ff !important;
  color: #3b82f6 !important;
}

.p-multiselect-token {
  background: #eff6ff !important;
  color: #3b82f6 !important;
  border-radius: 6px !important;
  padding: 0.25rem 0.5rem !important;
  margin: 0.25rem !important;
}

.p-multiselect-token-icon {
  color: #3b82f6 !important;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #94a3b8;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #64748b;
}

.leave-management-container {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-section {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.legend-container {
  display: flex;
  align-items: center;
}

.leave-legend {
  font-size: 0.9rem;
}

.legend-item {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  z-index: 1;
}

.employee-select {
  width: 100% !important;
  background: white;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.employee-select .p-multiselect-label {
  padding-left: 35px !important;
}

.leave-card {
  background: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.leave-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.user-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e9ecef;
}

.user-icon {
  font-size: 1.25rem;
  color: #4a90e2;
}

.user-name {
  margin: 0;
  font-weight: 600;
  color: #2c3e50;
}

.leave-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.leave-item {
  background: #f8f9fa;
  padding: 0.75rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leave-type {
  font-weight: 500;
  color: #2c3e50;
}

.leave-stats {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.available {
  color: #28a745;
}

.used {
  color: #dc3545;
}

.edit-button {
  color: #4a90e2;
  padding: 0.25rem;
  font-size: 0.9rem;
}

.edit-button:hover {
  color: #357abd;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem;
}

.page-info {
  font-size: 0.9rem;
  color: #6c757d;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.leave-edit-modal .modal-content {
  border-radius: 12px;
  border: none;
}

.leave-edit-modal .modal-header {
  background: #4a90e2;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.leave-edit-modal .modal-title {
  font-weight: 600;
}

.leave-edit-modal .close {
  color: white;
}

.form-control-modern {
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 0.75rem;
  transition: border-color 0.2s;
}

.form-control-modern:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 0 0.2rem rgba(74, 144, 226, 0.25);
}

.form-control-modern:read-only {
  background: #f8fafc;
}

.leave-dashboard {
  background: #f0f2f5;
  min-height: 100vh;
  padding: 2rem;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.header-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-title h2 {
  margin: 0;
  color: #1a3353;
  font-size: 1.75rem;
  font-weight: 600;
}

.calendar-icon {
  font-size: 1.75rem;
  color: #4361ee;
}

.search-wrapper {
  flex: 1;
  max-width: 400px;
}

.search-box {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #6b7280;
  z-index: 2;
}

.modern-select {
  width: 100% !important;
  background: white !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05) !important;
}

.modern-select .p-multiselect-label {
  padding: 0.75rem 1rem 0.75rem 2.5rem !important;
  font-size: 0.95rem !important;
}

.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.stat-icon.available {
  background: rgba(67, 97, 238, 0.1);
  color: #4361ee;
}

.stat-icon.used {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.stat-info h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a3353;
}

.stat-info p {
  margin: 0.25rem 0 0;
  color: #6b7280;
  font-size: 0.9rem;
}

.leave-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.leave-card-modern {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.leave-card-modern:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.card-header {
  background: linear-gradient(135deg, #4361ee 0%, #3730a3 100%);
  padding: 1.5rem;
  color: white;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.avatar {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.user-info h4 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
}

.leave-content {
  padding: 1.5rem;
}

.leave-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border-radius: 12px;
  background: #f8fafc;
  margin-bottom: 0.75rem;
  transition: background-color 0.2s;
}

.leave-row:hover {
  background: #f1f5f9;
}

.leave-type {
  font-weight: 500;
  color: #1a3353;
}

.leave-counts {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.count {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  padding: 0.4rem 0.75rem;
  border-radius: 8px;
}

.count.available {
  background: rgba(67, 97, 238, 0.1);
  color: #4361ee;
}

.count.used {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.edit-btn {
  background: none;
  border: none;
  color: #6b7280;
  padding: 0.4rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.edit-btn:hover {
  background: rgba(107, 114, 128, 0.1);
  color: #4361ee;
}

.modern-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.page-btn {
  background: white;
  border: 1px solid #e5e7eb;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #1a3353;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.page-btn:hover:not(:disabled) {
  background: #4361ee;
  color: white;
  border-color: #4361ee;
}

.page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  gap: 0.5rem;
}

.page-number {
  width: 36px;
  height: 36px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: #1a3353;
  cursor: pointer;
  transition: all 0.2s;
}

.page-number:hover:not(.active) {
  background: #f1f5f9;
}

.page-number.active {
  background: #4361ee;
  color: white;
  border-color: #4361ee;
}

.modern-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.spinner-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner-ripple div {
  position: absolute;
  border: 4px solid #4361ee;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.spinner-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.modern-modal .modal-content {
  border-radius: 16px;
  border: none;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.modern-modal .modal-header {
  background: linear-gradient(135deg, #4361ee 0%, #3730a3 100%);
  color: white;
  border-bottom: none;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 1.5rem;
}

.modern-modal .modal-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.modern-modal .close {
  color: white;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.modern-modal .close:hover {
  opacity: 1;
}

.modern-form {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  font-weight: 500;
  color: #1a3353;
  margin-bottom: 0.5rem;
}

.modern-input {
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  transition: all 0.2s;
}

.modern-input:focus {
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.1);
}

.modern-input:read-only {
  background: #f8fafc;
}

@media (max-width: 768px) {
  .leave-dashboard {
    padding: 1rem;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: stretch;
  }

  .search-wrapper {
    max-width: 100%;
  }

  .stat-card {
    padding: 1rem;
  }

  .leave-grid {
    grid-template-columns: 1fr;
  }

  .page-numbers {
    display: none;
  }
}

.leave-legend {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-item .value {
  width: auto;
  min-width: auto;
  padding: 0.25rem 0.5rem;
}
